import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list_item = _resolveComponent("list-item")
  const _component_van_tab = _resolveComponent("van-tab")
  const _component_van_tabs = _resolveComponent("van-tabs")

  return (_openBlock(), _createBlock(_component_van_tabs, {
    sticky: "",
    color: "#0065FF",
    "title-active-color": "#0065FF"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_van_tab, { title: "待我审批" }, {
        default: _withCtx(() => [
          _createVNode(_component_list_item, {
            type: "11",
            state: "1"
          })
        ]),
        _: 1
      }),
      _createVNode(_component_van_tab, { title: "我已审批" }, {
        default: _withCtx(() => [
          _createVNode(_component_list_item, {
            type: "12",
            state: "0"
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}